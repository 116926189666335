import React from "react"
import { Container, Row, Col } from "reactstrap"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./footer.scss"

import List2 from "../components/list2"

import Smartphone from "../assets/svg/solid/mobile-alt.svg"
import Telephone from "../assets/svg/solid/phone-alt.svg"
import Mail from "../assets/svg/regular/envelope.svg"
import Pin from "../assets/svg/solid/map-marker-alt.svg"

import FacebookSVG from "../assets/svg/facebook.svg"
import InstagramSVG from "../assets/svg/instagram.svg"
import WhatsappSVG from "../assets/svg/whatsapp.svg"
import ViberSVG from "../assets/svg/viber.svg"

const Footer = ({ siteMetadata }) => {
  const {
    title,
    about,
    email,
    social,
    contact,
    address,
    author,
  } = siteMetadata

  const data = useStaticQuery(graphql`
    query {
      classeMais: file(relativePath: { eq: "certifications/classeMais3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, placeholder: BLURRED)
        }
      }
      cortizo: file(relativePath: { eq: "certifications/cortizo2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, placeholder: BLURRED)
        }
      }
      wurth: file(relativePath: { eq: "certifications/wurth1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 90, height: 90, placeholder: BLURRED)
        }
      }
    }
  `)

  const badges = [
    { img: getImage(data.wurth.childImageSharp), alt: "Wurth" },
    { img: getImage(data.cortizo.childImageSharp), alt: "Cortizo" },
    { img: getImage(data.classeMais.childImageSharp), alt: "Classe +" },
  ]

  return (
    <footer>
      <section className="footer-wrapper">
        <Container className="footer-content text-white">
          <Row className="section-md">
            <Col className="company-badge" lg="4" sm="12">
              <h4 className="text-center font-weight-bold mt-4 mb-2">
                Certificações
              </h4>
              <section className="img-badge d-flex flex-wrap justify-content-around align-items-center">
                {badges.map((badge, index) => (
                  <div key={index} className="my-2">
                    <GatsbyImage
                      image={badge.img}
                      alt={badge.alt}
                      className="img-badge"
                    />
                  </div>
                ))}
              </section>
            </Col>
            <Col className="footer-aboutus" lg="4" sm="6">
              <h4 className="text-center font-weight-bold mt-4 mb-2">
                Sobre nós
              </h4>
              <p className="font-size-80 text-justify">{about}</p>
              <section className="footer-social">
                <Row className="ml-0">
                  {social.facebook && (
                    <a
                      key="facebook"
                      href={social.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="facebook"
                      aria-label="Visite nosso Facebook"
                    >
                      <FacebookSVG className="fill-svg" />
                      <span className="sr-only">Facebook</span>
                    </a>
                  )}
                  {social.instagram && (
                    <a
                      key="instagram"
                      href={social.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="instagram"
                      aria-label="Visite nosso Instagram"
                    >
                      <InstagramSVG className="stroke-svg" />
                      <span className="sr-only">Instagram</span>
                    </a>
                  )}
                  {social.whatsapp && (
                    <a
                      key="whatsapp"
                      href={social.whatsapp}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="whatsapp"
                      aria-label="Envie uma mensagem no WhatsApp"
                    >
                      <WhatsappSVG className="fill-svg" />
                      <span className="sr-only">WhatsApp</span>
                    </a>
                  )}
                  {social.viber && (
                    <a
                      key="viber"
                      href={social.viber}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="viber"
                      aria-label="Envie uma mensagem no Viber"
                    >
                      <ViberSVG className="fill-svg" />
                      <span className="sr-only">Viber</span>
                    </a>
                  )}
                </Row>
              </section>

            </Col>
            <Col className="footer-contact" lg="4" sm="6">
              <h4 className="text-center font-weight-bold mt-4 mb-2">
                Contactos
              </h4>
              <ul className="list-unstyled">
                <li key="mobile" className="my-2">
                  <List2 svg={<Smartphone />} value={contact.mobile} />
                </li>
                <li key="telephone" className="my-2">
                  <List2 svg={<Telephone />} value={contact.telephone} />
                </li>
                <li key="email" className="my-2">
                  <List2 svg={<Mail />} value={email} />
                </li>
                <li key="address" className="my-2">
                  <List2 svg={<Pin />} value={address} />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Row className="footer-credits justify-content-center">
          {` © ${title} 2024 | Code and design by: `}
          <a
            href={author.website}
            target="_blank"
            rel="noopener noreferrer"
            title={author.position}
          >
            {author.name}
          </a>
        </Row>
      </section>
    </footer>
  )
}

export default Footer
