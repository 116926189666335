import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Header from "./header";
import Footer from "./footer";
import "./layout.scss";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          about
          email
          social {
            facebook
            instagram
            whatsapp
            viber
          }
          contact {
            mobile
            telephone
          }
          address
          opening {
            day
            hour
          }
          author {
            name
            website
            position
          }
        }
      }
      file(relativePath: { eq: "logo-transparent.png" }) {
        childImageSharp {
          gatsbyImageData(width: 55, height: 55, quality: 90, layout: CONSTRAINED)
        }
      }
    }
  `);

  const logoImage = data.file.childImageSharp.gatsbyImageData;

  return (
    <div className="layout">
      <Helmet>
        {/* Meta for Open Graph */}
        <link rel="icon" href="/favicon.png" type="image/png" />
        <meta property="og:image" content={logoImage.images.fallback.src} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Meta for Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={logoImage.images.fallback.src} />

        {/* Meta description */}
        <meta name="description" content={data.site.siteMetadata.about} />
        <meta name="keywords" content="vinco, janelas, portas, marquises, portões" />
      </Helmet>

      <Header siteTitle={data.site.siteMetadata.title} logo={logoImage} />
      <main>{children}</main>
      <Footer siteMetadata={data.site.siteMetadata} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
