import React, { useState } from "react" // Importă useState pentru a gestiona starea
import { Link } from "gatsby"
import { Container, Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image" // Importă GatsbyImage și getImage
import { useStaticQuery, graphql } from "gatsby" // Importă useStaticQuery

import "./header.scss"

const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false) // Folosește useState pentru a gestiona starea

  // Funcția pentru a comuta starea meniului
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  // Folosește useStaticQuery pentru a obține imaginea
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-transparent-only.png" }) {
        childImageSharp {
          gatsbyImageData(width: 55, height: 55, quality: 90, layout: CONSTRAINED)
        }
      }
    }
  `)

  // Obține imaginea optimizată
  const logoImage = getImage(data.file.childImageSharp.gatsbyImageData)

  // Funcția pentru a închide meniul
  const closeMenu = () => {
    setIsOpen(false)
  }

  return (
    <div className="header">
      <Navbar color="blue" dark expand="md" className="navbar fixed-top">
        <Container>
          <Link to="/#hero" className="navbar-brand" onClick={closeMenu}>
            {/* Folosește GatsbyImage pentru a renderiza imaginea optimizată */}
            <GatsbyImage
              image={logoImage}
              alt={`${siteTitle} Logo`}
              className="mr-2"
              loading="lazy"
            />
          </Link>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ color: '#FFA500' }}
                  to="/#hero"
                  onClick={closeMenu}
                >
                  Início
                </Link>
              </NavItem>
              <NavItem>
                <Link 
                  className="nav-link" 
                  activeStyle={{ color: '#FFA500' }}
                  to="/projects"
                  onClick={closeMenu}
                >
                  Projetos
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ color: '#FFA500' }}
                  to="/services"
                  onClick={closeMenu}
                >
                  Produtos
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ color: '#FFA500' }}
                  to="/about"
                  onClick={closeMenu}
                >
                  Sobre nós
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  activeStyle={{ color: '#FFA500' }}
                  to="/contact"
                  onClick={closeMenu}
                >
                  Contactos
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header
